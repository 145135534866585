import {motion, AnimatePresence} from 'framer-motion';
import React, {Ref, useEffect, useState} from 'react';
import {useIntersectionObserver} from '@uidotdev/usehooks';
import Image from 'next/legacy/image';

import GraphOnly from './assets/graph-only.png';
import IcicleAndSource from './assets/source.png';
import MetricsLabel from './assets/metrics-label.png';
import Compare from './assets/compare.png';

const SLIDER_DURATION_IN_MS = 5000;
const SLIDER_DURATION_IN_SECONDS = SLIDER_DURATION_IN_MS / 1000;

const sliderItemsWithCaption = [
  {
    image: GraphOnly,
    caption:
      "Visualize and understand how your application behaves with Polar Signals Cloud's Icicle-Graph visualization.",
  },
  {
    image: MetricsLabel,
    caption:
      'Polar Signals Cloud is equipped with a Label-selector based query language allowing you to query your data with ease.',
  },
  {
    image: Compare,
    caption:
      'Compare profiles side-by-side in various dimensions to discover opportunities for optimization.',
  },
  {
    image: IcicleAndSource,
    caption:
      'Detect performance regressions, optimizations, or potential issues introduced in the codebase down to the line of code.',
  },
];

const sliderItemsAnimationProps = {
  initial: {
    opacity: 0,
    translateY: 20,
  },
  animate: {
    opacity: 1,
    translateY: 0,
    transition: {ease: [0.25, 0.1, 0, 1], duration: 1},
  },
  exit: {
    opacity: 0,
    transition: {ease: [0.25, 0.1, 0, 1], duration: 0.3},
  },
};

const Slider = ({
  items = sliderItemsWithCaption,
  sliderMovementBackground = 'linear-gradient(267.71deg, #A7EAD5 -0.04%, #00CCC0 68.91%)',
}: {
  items?: typeof sliderItemsWithCaption;
  sliderMovementBackground?: string;
}) => {
  const [sliderRef, isSliderInView] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '-150px',
  });
  const [activeSliderItemIndex, setActiveSliderItemIndex] = useState(0);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (isSliderInView?.isIntersecting) {
      timeout = setTimeout(() => {
        setActiveSliderItemIndex(
          activeSliderItemIndex => (activeSliderItemIndex + 1) % items.length
        );
      }, SLIDER_DURATION_IN_MS);
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [activeSliderItemIndex, isSliderInView]);

  return (
    <div ref={sliderRef as Ref<HTMLDivElement>}>
      <div className="relative">
        <AnimatePresence initial={false} exitBeforeEnter>
          {items.map((item, index) =>
            index === activeSliderItemIndex ? (
              <motion.div
                className="build-faster-image-container relative z-10 h-[300px] rounded-xl md:h-[550px] lg:h-[540px] lg:w-[721px]"
                key={index}
                onClick={() => {
                  setActiveSliderItemIndex(
                    activeSliderItemIndex => (activeSliderItemIndex + 1) % items.length
                  );
                }}
                {...sliderItemsAnimationProps}
              >
                <Image
                  className="rounded-xl"
                  src={item.image}
                  alt="Polar Signals Cloud Profiling Dashboard"
                  objectFit="contain"
                  layout="intrinsic"
                  loading="lazy"
                />
              </motion.div>
            ) : null
          )}
        </AnimatePresence>
      </div>
      <div className="mt-2 max-w-[721px] pl-4">
        <AnimatePresence initial={false} exitBeforeEnter>
          {items.map((item, index) =>
            index === activeSliderItemIndex ? (
              <motion.div
                className="flex flex-col items-start gap-4 text-center"
                key={index}
                {...sliderItemsAnimationProps}
              >
                {item.caption && (
                  <p className="text-left text-sm font-semibold text-gray-900">{item.caption}</p>
                )}
              </motion.div>
            ) : null
          )}
        </AnimatePresence>
      </div>
      <ul className="flex items-center space-x-5 pl-4">
        {items.map((_, index) => (
          <li key={index}>
            <button
              className="h-1.5 w-8 overflow-hidden rounded-2xl bg-gray-100 lg:h-2.5 lg:w-12"
              type="button"
              aria-label={`Go to illustration ${index + 1}`}
              onClick={() => setActiveSliderItemIndex(index)}
            >
              {index === activeSliderItemIndex && isSliderInView && (
                <motion.span
                  className="block h-full rounded-2xl"
                  initial={{width: 0}}
                  animate={{
                    width: '100%',
                    transition: {duration: SLIDER_DURATION_IN_SECONDS},
                  }}
                  style={{
                    background: sliderMovementBackground,
                  }}
                />
              )}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Slider;
